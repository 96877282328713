// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aulas-js": () => import("./../../../src/pages/aulas.js" /* webpackChunkName: "component---src-pages-aulas-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-planilhas-gratuitas-js": () => import("./../../../src/pages/planilhas-gratuitas.js" /* webpackChunkName: "component---src-pages-planilhas-gratuitas-js" */),
  "component---src-pages-planos-js": () => import("./../../../src/pages/planos.js" /* webpackChunkName: "component---src-pages-planos-js" */),
  "component---src-pages-treinamentos-js": () => import("./../../../src/pages/treinamentos.js" /* webpackChunkName: "component---src-pages-treinamentos-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

